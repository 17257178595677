import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import Participants from './Participants';
import NoHost from './NoHost';
import Log from './Log';
import zoomSdk from "@zoom/appssdk";
import useSWRSubscription from 'swr/subscription';
import { endpoint } from './utils';

// const STATUS_POLLING = 200;
const DOWNLOAD_LINK = "https://www.polyguard.ai/get-started";

 
const openURL = async (url) => {
  try {
    await zoomSdk.openUrl({ url: url });
  } catch (error) {
    console.error('Error opening URL:', error);
  }
};

// const fetcher = async url => {
//   // console.log(url);
//   const res = await fetch(url, { cache: "no-store" });
//   return await res.json();
// };



function Sidebar({ meetingUUID }) {
  const [messageLog, setMessageLog] = useState({});
  const [previousStatuses, setPreviousStatuses] = useState({});
  const [currentStatuses, setCurrentStatuses] = useState({});
  const [userContext, setUserContext] = useState({});

  // const { data: rawData } = useSWR(
  //   `${endpoint()}/verify-meeting/${encodeURIComponent(meetingUUID)}`,
  //   fetcher,
  //   {
  //     // fallbackData: {},
  //     revalidateOnMount: false,
  //     refreshWhenHidden: true,
  //     refreshInterval: STATUS_POLLING,
  //   }
  // );

  const { data: rawData } = useSWRSubscription(`wss://${endpoint()}/meeting_session/${encodeURIComponent(meetingUUID)}`, (key, { next }) => {
    const socket = new WebSocket(key)
    socket.addEventListener('message', (event) => next(null, event.data))
    socket.addEventListener('error', (event) => next(event.error))
    return () => socket.close()
  })

  useEffect(() => {
    const fetchUserContext = async () => {
      try {
        const context = await zoomSdk.getUserContext();
        setUserContext(context);
      } catch (error) {
        console.error('Error fetching user context:', error);
        throw error;
      }
    };
    fetchUserContext();
  }, []);

  useEffect(() => {
    if (rawData) {
      const parsedData = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
      console.log('Parsed data:', parsedData);
      setCurrentStatuses(parsedData);
    }
  }, [rawData]);

  useEffect(() => {
    function checkForImportantChanges(newStatuses, oldStatuses) {
      return Object.entries(newStatuses).some(([key, newStatus]) => {
        if ((oldStatuses[key]?.status !== newStatus?.status)) {  // && (newStatus === 'unverified' || newStatus === 'unknown')) {
          console.log('Important status change detected:', key, oldStatuses[key]?.status, newStatus?.status);
          return true;
        }
        return false;
      });
    }

    if (previousStatuses && currentStatuses) {
      if (checkForImportantChanges(currentStatuses, previousStatuses)) {
        console.log('Important status change detected. Bringing app to front.');
        zoomSdk.bringAppToFront().then(() => {
          console.log('App brought to front because of important status change.');
        }).catch((error) => {
          console.error('Error bringing app to front:', error);
        });
      }
    }
    setPreviousStatuses(currentStatuses);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatuses]);

  useEffect(() => {
    const timenow = Date.now()  / 1000;
    setMessageLog(m => {
      const new_m = { ...m };
      new_m[timenow] = Object.keys(currentStatuses ?? {}).map(
        p => `${currentStatuses[p]?.screenName ?? '--'}: ${currentStatuses[p].message}`
      );
      return new_m;
    });
  }, [currentStatuses]);

  const currentUserStatus = currentStatuses[userContext?.participantUUID]?.status;

  const handleHideApp = async () => {
    try {
      await zoomSdk.sendAppToBackground();
    } catch (error) {
      console.error('Error sending app to background:', error);
    }
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        {Object.keys(currentStatuses).length > 0 ? <Participants statuses={currentStatuses} currentUserUUID={userContext?.participantUUID} /> : <NoHost meetingUUID={meetingUUID} />}
        {currentUserStatus === 'unknown' && (
          <div className="unknown-user-prompt">
            <button onClick={() => openURL(DOWNLOAD_LINK)} className="button-as-link">
              Download the Polyguard Desktop Camera
            </button>
          </div>
        )}
        <Log logEntries={messageLog} />
      </div>
    <div className="feedback-button">
      <button onClick={handleHideApp} className="button-as-link">
        Hide Polyguard
      </button>
      <span className="button-separator">|</span>
      <button onClick={() => openURL("https://www.polyguard.ai/support")} className="button-as-link">
        Share Feedback
      </button>
    </div>
  </div>
  );
}

export default Sidebar;
