
//function to get the meeting number and passwork from the url
export function getMeetingNumberAndPasswordFromUrl(url) {
  const splitUrl = url.split('?')[0];

  if (!splitUrl) {
    return {
      meetingNumber: null,
      password: null
    };
  }

  const meetingNumber = splitUrl.substring(splitUrl.lastIndexOf('/') + 1);
  const queryString = url.split('?')[1];
  const password = queryString ? queryString.split('pwd=')[1] : null;

  return {
    meetingNumber,
    password
  };
}

export const endpoint = () => {
  // Get the current URL
  const url = new URL(window.location.href);
  // Get the hostname (domain) of the URL
  const hostname = url.hostname;
  if (["ux.actual.video"].includes(hostname)) {
    return "api.actual.video";
  } else if (["dev-ux.actual.video", "zoom-app-polyguard.vercel.app"].includes(hostname)) {
    return "dev-api.actual.video";
  } else {
    return "local-api.actual.video";
  }
}

export const envLabel = () => {
  const url = new URL(window.location.href);
  const hostname = url.hostname;
  if (["ux.actual.video"].includes(hostname)) {
    return { label: "", color: "green", env: "prod" };
  } else if (["dev-ux.actual.video", "zoom-app-polyguard.vercel.app"].includes(hostname)) {
    return { label: "STAGING", color: "brown", env: "staging" };
  } else {
    return { label: "LOCAL DEV", color: "orange", env: "local" };
  }
}


export const botLaunchEndpoint = () => {
  const url = new URL(window.location.href);
  const hostname = url.hostname;
  if (["ux.actual.video"].includes(hostname)) {
    return "botlauncher.actual.video";
  } else if (["dev-ux.actual.video", "zoom-app-polyguard.vercel.app"].includes(hostname)) {
    return "dev-botlauncher.actual.video";
  } else {
    return "local-bot.actual.video";
  }
}