import React, { useEffect, useRef } from 'react';
import './NoHost.css';
import zoomSdk from "@zoom/appssdk";
import { envLabel, botLaunchEndpoint, endpoint } from './utils';

function NoHost({ meetingUUID }) {
  const timerRef = useRef(null);

  const inviteBot = async () => {
    var meetingURL;
    try {
      meetingURL = await zoomSdk.getMeetingJoinUrl();
    } catch (error) {
      return;
    }

    console.log('Meeting URL:', meetingURL);
    const response = await fetch(`https://${botLaunchEndpoint()}/launch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url: meetingURL.joinUrl })
    });
    console.dir(response);

    const partList = await zoomSdk.getMeetingParticipants();
    const partMap = {
      meeting_uuid: meetingUUID,
      participants: partList.participants
    };
    console.dir(partMap);
    const partResponse = await fetch(`https://${endpoint()}/meeting-details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(partMap)
    });
    console.dir(partResponse);
  };

  useEffect(() => {
    const environ = envLabel();
    if (environ.env !== 'prod') {
      return;
    }
    timerRef.current = setTimeout(async () => {
      try {
        const response = await zoomSdk.sendAppInvitationToMeetingOwner();
        console.log('Invitation sent to meeting owner:', response);
        clearInterval(timerRef.current);
      } catch (error) {
        console.error('Failed to send invitation to meeting owner:', error);
      }
    }, 5000); // note: if the component rerenders, this will reset the timer

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div className="no-host-section">
      <h2>Bot Required</h2>
      <p>
        The Polyguard Bot has not joined this meeting. Some features will not be available until the bot joins the app.
        {/* <input type="text" id="meetingUrl" /> */}
        <button onClick={inviteBot}>Invite Bot Manually</button>
      </p>
    </div>
  );
}

export default NoHost;
