import React, { useState, useEffect } from 'react';
import './Header.css';
import { envLabel } from './utils';

function Header({callLength}) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const environ = envLabel();
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 6000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  
  const formattedDate = currentTime.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric'});
  const formattedTime = currentTime.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true});
  return (
    <div className="header">
      {environ.env !== 'prod' ? (<h1 style={{backgroundColor: environ.color}}>{environ.label}</h1>) : null}
      <img src="PolyguardLogo.svg" alt="Logo" width={275} />
      <p className="callInfo">{formattedTime} - {formattedDate}</p>
    </div>
  );
}

export default Header;
