import React from 'react';
import PropTypes from 'prop-types';

const ParticipantStatuses = ({ statusValue, statusType, screenName, iconSrc, companyName }) => {
  const getIconClass = (status) => {
    switch (status) {
      case 'verified':
        return 'icon-verified';
      case 'unverified':
        return 'icon-unverified';
      case 'unknown':
      default:
        return 'icon-unknown';
    }
  };

  const getStatusText = (statusValue, statusType, screenName, companyName) => {
    switch (statusType) {
      case 'g2gStatus':
        return statusValue === 'verified' 
        ? `This video is perceptually the same as that of the camera.` 
        : statusValue === 'unverified' ? `This video does not perceptually match what the camera is seeing.` 
        : `The Polyguard Desktop Client is not installed or enabled.`;
      case 'nameStatus':
        return statusValue === 'verified' 
        ? `'${screenName}' has verified themself against a government ID on this device.` 
        : statusValue === 'unverified' ? `Something is not right. This is likely not ${screenName}.` 
        : `'${screenName}' provided by Apple ID (unverified). \nNo document verification completed.`;
      case 'faceStatus':
        return statusValue === 'verified' 
        ? `Biometric authentication has verified this as ${screenName}'s face.` 
        : statusValue === 'unverified' ? `${screenName} is not who you are seeing.` 
        : `Biometrics authentication has not been setup on ${screenName}'s device.`;
      case 'businessStatus':
        return statusValue === 'verified' 
        ? `${screenName} has been verified as employed by ${companyName}.` 
        : statusValue === 'unverified' ? `${screenName} does not work at ${companyName}.` 
        : `${screenName} has not verified their place of work.`;
      default:
        return '';
    }
  };

  return (
    <div className="participant-statuses">
      <img src={iconSrc} alt={`${statusType} Status`} className={`icon small-icon ${getIconClass(statusValue)}`} />
      <span className={getIconClass(statusValue)}>{getStatusText(statusValue, statusType, screenName, companyName)}</span>
    </div>
  );
};

ParticipantStatuses.propTypes = {
  statusValue: PropTypes.string,
  statusType: PropTypes.oneOf(['g2gStatus', 'nameStatus', 'faceStatus', 'businessStatus']).isRequired,
  iconSrc: PropTypes.string.isRequired,
  screenName: PropTypes.string,
  companyName: PropTypes.string
};

export default ParticipantStatuses;