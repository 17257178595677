import React from 'react';
import './Log.css';
import { useCollapse } from 'react-collapsed'

import * as dayjs from 'dayjs'
import * as RelativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(RelativeTime);


function Log({logEntries}) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <div className="log-section">
      <h2>
      <button {...getToggleProps()}>
        Log  {isExpanded ? 'Collapse' : 'Expand'}
      </button>
      </h2>
      <section {...getCollapseProps()} className="log-box">
        {   Object.entries(logEntries).reverse().map(([key, value]) =>  
    value.map((entry, index) => <div key={`log-${key}-${index}`} className="log-entry">{dayjs.unix(key).format("h:mm:ss")} {entry}</div>)).flat() }
      </section>
    </div>
  );
}

export default Log;
