import React from 'react';
import './Participants.css';
import zoomSdk from "@zoom/appssdk";
import ParticipantStatuses from './ParticipantStatuses';
import useColorChange from 'use-color-change';

const BOT_NAMES = ["Polyguard Bot"];

const getIconClass = (status) => {
  switch (status) {
    case 'verified':
      return 'icon-verified';
    case 'unverified':
      return 'icon-unverified';
    case 'unknown':
    default:
      return 'icon-unknown';
  }
};


function IconRow({ statuses : {g2gStatus, nameStatus, faceStatus, businessStatus}, latestDistance }) {
  const colorStyle = useColorChange(latestDistance, {
      higher: 'crimson',
      lower: 'limegreen',
      duration: 1800
  });
  return (<div className="preview">
    <img src={`${process.env.PUBLIC_URL}/eye-solid.svg`} alt="G2G Status" className={`icon small-icon ${getIconClass(g2gStatus)}`} />
    <img src={`${process.env.PUBLIC_URL}/id-card-solid.svg`} alt="Name Status" className={`icon small-icon ${getIconClass(nameStatus)}`} />
    <img src={`${process.env.PUBLIC_URL}/fingerprint-solid.svg`} alt="Face Status" className={`icon small-icon ${getIconClass(faceStatus)}`} />
    <img src={`${process.env.PUBLIC_URL}/building-circle-exclamation-solid.svg`} alt="Business Status" className={`icon small-icon ${getIconClass(businessStatus)}`} />
    <span style={colorStyle}> &nbsp;({latestDistance})</span>
  </div>);

}

function Participants({ statuses, currentUserUUID }) {
  if (!statuses) {
    return <div className="participants-section">Loading participants...</div>;
  }

  console.log('Participants Statuses:', statuses);

  const handleSendAppInvitation = async (participantUUID) => {
    try {
      const response = await zoomSdk.sendAppInvitation({
        participantUUIDs: [participantUUID]
      });
      console.log('Invitation sent successfully:', response.invitationUUID);
    } catch (error) {
      console.error('Failed to send app invitation:', participantUUID, error);
    }
  };

  return (
    <div className="participants-section">
      <h2>Participants</h2>
      <ul>
        {Object.keys(statuses).map((uuid) => {
          const participant = statuses[uuid];
          if (!participant.screenName) return <></>;

          if (BOT_NAMES.includes(participant.screenName)) {
            return <li key={uuid} className="bot">{participant.screenName}</li>;
          }

          // const { g2gStatus, nameStatus, faceStatus, businessStatus, companyName } = participant.statuses;

          return (
            <li key={uuid} className={
              participant.status === 'unknown' ? 'participant unknown' :
                participant.status === 'verified' ? 'participant verified' :
                  participant.status === 'unverified' ? 'participant unverified' : 'participant'
            }>
              <details className="participant-details">
                <summary className="participant-header">
                  {participant.screenName}
                  {participant.status === 'unknown' ? (
                    currentUserUUID !== uuid &&
                    <button onClick={() => handleSendAppInvitation(uuid)}>Invite to verify</button>
                  ) : (<IconRow statuses={participant.statuses} latestDistance={participant.latestDistance} />)}
                </summary>
                <div className="details-content">
                  {participant.status === 'unknown'
                    ? (currentUserUUID !== uuid ? <p>Polyguard Desktop Client needs to be installed or enabled by {participant.screenName} to verify.</p> :
                      <p>Install the Polyguard Desktop Client to verify your video.</p>
                    )
                    : <>
                      <ParticipantStatuses statusValue={participant.statuses.g2gStatus} statusType="g2gStatus" iconSrc={`${process.env.PUBLIC_URL}/eye-solid.svg`} screenName={participant.screenName} />
                      <ParticipantStatuses statusValue={participant.statuses.nameStatus} statusType="nameStatus" iconSrc={`${process.env.PUBLIC_URL}/id-card-solid.svg`} screenName={participant.statuses.appleName} />
                      <ParticipantStatuses statusValue={participant.statuses.faceStatus} statusType="faceStatus" iconSrc={`${process.env.PUBLIC_URL}/fingerprint-solid.svg`} screenName={participant.screenName} />
                      <ParticipantStatuses statusValue={participant.statuses.businessStatus} statusType="businessStatus" iconSrc={`${process.env.PUBLIC_URL}/building-circle-exclamation-solid.svg`} screenName={participant.screenName} companyName={participant.statuses.companyName} />
                      <em>Latest Distances: {participant.distances ? JSON.stringify(Object.values(participant.distances)) : 'nil'}</em>
                    </>
                  }
                </div>
              </details>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Participants;

// Assumed structure of participant object:
// {
//   screenName: "Participant Name",
//   status: "verified", // overall status
//   statuses: {
//     g2gStatus: "verified",
//     nameStatus: "verified",
//     faceStatus: "unverified",
//     businessStatus: "unknown",
//     companyName: "Company Name"
//   },
//   details: "Detailed content goes here..."
// }