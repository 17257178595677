import React, { useEffect, useState } from 'react';
import './App.css';
import zoomSdk from "@zoom/appssdk";
import Sidebar from './Sidebar.js';
import Header from './Header';
import staticData from './data.json';

const DOWNLOAD_LINK = "https://www.polyguard.ai/beta";

function App() {
  const [meetingUUID, setMeetingUUID] = useState();
  const [configState, setConfigState] = useState([]);
  const [runningContext, setRunningContext] = useState(null);
// TODO: Add a periodic check for changes to the running context
  useEffect(() => {

    async function zoomConfig() {
      console.log("Calling zoomsdk config...");
      const receivedConfigState = await zoomSdk.config({
        popoutSize: { width: 480, height: 720 },
        capabilities: [
          "shareApp",
          "getMeetingJoinUrl",
          "getMeetingParticipants",
          "getMeetingUUID",
          "getSupportedJsApis",
          "sendAppInvitationToMeetingOwner",
          "bringAppToFront",
          "onShareScreen",
          "sendAppInvitation",
          "sendAppToBackground"
        ]
      });
      setConfigState(receivedConfigState);
      const runningContext = await zoomSdk.getRunningContext();
      setRunningContext(runningContext.context);

      if (runningContext.context === "inMeeting") {
          const meetingUUIDResponse = await zoomSdk.getMeetingUUID();
          if (meetingUUIDResponse?.meetingUUID) {
            setMeetingUUID(meetingUUIDResponse?.meetingUUID);
          }
      }
    }
    if (!runningContext) zoomConfig();
  }, []);

  const handleOpenUrl = async () => {
    try {
      await zoomSdk.openUrl({ url: DOWNLOAD_LINK });
    } catch (error) {
      console.error('Error opening URL:', error);
    }
  };

  return (
    <div className="App">
      <Header callLength={staticData.header.callLength} />
      {runningContext === "inMeeting" && meetingUUID ? (
        <Sidebar meetingUUID={meetingUUID} />
      ) : (
        <div>
          <div className="product">
            <h1 className="header-title">Polyguard - Verified Video Calling</h1>
            <p className="tagLine">A user-friendly app that guarantees that <strong>who</strong> you see on your video call is <strong>who</strong> they really are.</p>
          </div>
          <div className="install-link">
            <button onClick={handleOpenUrl} className="button-as-link">
              Download Polyguard Desktop Camera
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
